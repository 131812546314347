import React, { useRef } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import ClientSlider from './ClientSlider';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Slide } from 'react-awesome-reveal';

let clients = [
    {
        name : "Leo Grundström",
        position : "Founder of howtoai",
        img_url : "https://yt3.ggpht.com/NMsnrGZ6bQg855zixohbNtjb4E0HN8rHgbIPWZsnZ_HP8jWxwDHf0rmN87QUwQIdxX1kU2xgR2E=s800-c-k-c0x00ffffff-no-rj",
        stars : 5,
        disc : `I've collaborated with Ravi & his team for 6+ months, creating 40+ videos and gaining 11,000+ subscribers from scratch! Their high-quality work surpasses other editors I've tried. Rated 5/5, will keep using Ravi's services.`
    },
    {
        name : "Debarun",
        position : "FreelanceX",
        img_url : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
        stars : 5,
        disc : `The guy put some magic into my boring video made it alive! You guys can see the sample below. 🙌 Just Wow!! Highly recommended! 5/5.`
    },
    {
        name : "Mike saaranen",
        position : "Fiverr",
        img_url : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
        stars : 5,
        disc : `Great service. I gave Ravi a difficult topic to work with and no direction and the results were very impressive. I would definitely work with Ravi again. 5 Stars!.`
    },
    {
        name : "Uniquepivott",
        position : "Fiverr",
        img_url : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
        stars : 5,
        disc : `I’m so happy with the videos I got! They look professional and his response time was awesome 👏🏼 I will definitely be using his services again!.`
    },
    {
        name : "Keem",
        position : "Fiverr",
        img_url : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
        stars : 5,
        disc : `Good quality, quick delivery and was helpful with the revisions. Looking forward to sharing my course!`
    },
    {
        name : "Nate Page",
        position : "Fiverr",
        img_url : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
        stars : 5,
        disc : `Only took two revisions and that was because of the voice dictation being used and color scheme. Got these done in a week and I am happy with the work! Thank you.`
    },
    {
        name : "Signaring",
        position : "Fiverr",
        img_url : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
        stars : 5,
        disc : `Ravi gave me an excellent edit totally according to my instructions and expectations. He worked really hard to create a compelling and engaging youtube video. Thank you!.`
    },
    {
        name : "Pammorgan",
        position : "Fiverr",
        img_url : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
        stars : 5,
        disc : `Wow! I'm very happy with the final video ads that Ravi created for me. He was great to work with, even suggesting improvements that I had not considered. I will definitely reach out to him again for future projects
        .`
    },

    {
        name : "SignarLiam_jensening",
        position : "Fiverr",
        img_url : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
        stars : 5,
        disc : `Collaborating with Ravi was an extraordinarily seamless experience. His meticulous attention to detail, exemplary communication skills, and punctuality in completing tasks truly set him apart
        .`
    },
]
var settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows : false,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 530,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]}

const Clients = () => {
    const arrowRef = useRef(null);
    let clientDisc = "";
    clientDisc = clients.map((item, i) => (
        <ClientSlider item={item} key={i}/>
    ))
  return (
    <Container id='client'>
        <Slide direction="left">
            <span className="green">testimonials</span>
            <h1>what clients say</h1>
        </Slide>
        <Testimonials>
            <Slider ref={arrowRef} {...settings}>
                {clientDisc}
            </Slider>
            <Buttons>
                <button
                onClick={() => arrowRef.current.slickPrev()}
                ><IoIosArrowBack/></button>
                <button
                onClick={() => arrowRef.current.slickNext()}
                ><IoIosArrowForward/></button>
            </Buttons>
        </Testimonials>
    </Container>
  )
}

export default Clients

const Container = styled.div`
    width: 80%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 4rem 0;

    @media(max-width:840px){
        width: 90%;
    }

    span{
        font-weight: 700;
        text-transform: uppercase;
    }

    h1{
        padding-top: 1rem;
        text-transform: capitalize;
    }

    .slick-list, .slick-slider, .slick-track{
        padding: 0;
    }

    .slick-dots{
        text-align: left;
        margin-left: 1rem;
    }

    .slick-dots li button:before{
        content: "";
    }

    .slick-dots li button{
        width: 9px;
        height: 4px;
        background: linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%);
        padding: 0.1rem;
        margin-top: 1rem;
        transition: all 400ms ease-in-out;
        border-radius: 50px;
    }
    
    .slick-dots li.slick-active button{
        background: #01be96;
        width: 15px;
    }

    .slick-dots li{
        margin: 0;
    }
`

const Testimonials = styled.div`
    margin-top: 2rem;
    position: relative;
`
const Buttons = styled.div`
    position: absolute;
    right: 0.7rem;
    bottom: -2rem;

    button{
        background-color: transparent;
        margin-left: 0.5rem;
        border: none;
        color: #01be96;
        cursor: pointer;
        font-size: 1.1rem;
    }

    @media(max-width:530px){
        display: none;
    }
`