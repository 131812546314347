import React from "react";
import styled from "styled-components";
import { AiOutlineInstagram } from "react-icons/ai";

import { FaLinkedinIn } from "react-icons/fa";
import { AiFillMail } from "react-icons/ai";
import { GiEarthAmerica } from "react-icons/gi";
import { Slide } from "react-awesome-reveal";
import profileImage from "./image/Ravi.png";


const ProfComponent = () => {
  return (
    <Container id="home">
      <Slide direction="left">
        <Texts>
          <h4>
          🎬 Welcome! <span className="green">I'am</span>
          </h4>
          <h1 className="green">Ravi Parihar</h1>
          <h3> A professional Video Editor </h3>
          <p>
          With four years of experience, I've collaborated with clients from 15 different countries, completing hundreds of projects, including YouTube videos as well as Shorts, Reels, and TikTok content 🎥.
         <br></br>
        
           {/* My tools of choice? Adobe Premiere Pro and Adobe After Effects - these are my brushes for crafting stunning video content. From captivating social media clips to professional corporate videos, pulsating music videos, exciting travel vlogs, elegant wedding videos, compelling commercials, and much more, I've dived into diverse realms of video creation.
           <br></br>
           Passion drives me - I'm dedicated to bringing out the best in every project I undertake. If you're seeking a creative partner to elevate your video,
           <br></br> */}
          {/* Let's transform your vision into an awe-inspiring reality 🚀. */}
            {/* 🎬 Welcome to my world of captivating visual narratives! With over four years of dedicated video editing experience, I've mastered the art of weaving stories that resonate across diverse video formats. */}
          
            {/* 🌟 My Expertise:
            From dynamic YouTube talking head videos to compelling Cashcow content, polished corporate visuals to engaging podcast and real estate videos, I've polished my skills while editing thousands of projects.

            🤝 Collaborative Success:
            I take pride in my collaborations with prominent YouTube channels, playing a pivotal role in enhancing their success through my editing prowess.

            🔍 Explore My Portfolio:
            Step into my portfolio and witness firsthand how I breathe life into stories through the magic of video editing.

            Let's bring your vision to life through the power of visual storytelling. Get in touch and let's craft something amazing together. */}
          </p>
          <button>Let's talk</button>
          <Social>
            <p>Check out my</p>
            <div className="social-icons">
              <span>
                <a href="https://www.instagram.com/iamraviparrihar/">
                  <AiOutlineInstagram />
                </a>
              </span>
      
              <span>
                <a href="https://www.linkedin.com/in/ravi-parihar-16a4691a9?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">
                  <FaLinkedinIn />
                </a>
              </span>
              <span>
                <a href="mailto:videoeditorravi@gmail.com">
                  <AiFillMail />
                </a>
              </span>
              <span>
                <a href="/">
                  <GiEarthAmerica />
                </a>
              </span>
            </div>
          </Social>
        </Texts>
      </Slide>
      <Slide direction="right">
        <Profile>
          <img
            src={profileImage}
            alt="profile"
          />
        </Profile>
      </Slide>
    </Container>
  );
};

export default ProfComponent;

const Container = styled.div`
  display: flex;
  gap: 2rem;
  padding-top: 3rem;
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  z-index: 1;
  @media (max-width: 840px) {
    width: 90%;
  }

  @media (max-width: 640px) {
    flex-direction: column;
  }
`;
const Texts = styled.div`
  flex: 1;
  h4 {
    padding: 1rem 0;
    font-weight: 500;
  }
  h1 {
    font-size: 2rem;
    font-family: "Secular One", sans-serif;
    letter-spacing: 2px;
  }
  h3 {
    font-weight: 500;
    font-size: 1.2rem;
    padding-bottom: 1.2rem;
    text-transform: capitalize;
  }
  p {
    font-weight: 300;
  }

  button {
    padding: 0.7rem 2rem;
    margin-top: 3rem;
    cursor: pointer;
    background-color: #01be96;
    border: none;
    color: #fff;
    font-weight: 500;
    filter: drop-shadow(0px 10px 10px #01be9551);
    :hover {
      filter: drop-shadow(0px 10px 10px #01be9570);
    }
  }
`;
const Social = styled.div`
  margin-top: 3rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  p {
    font-size: 0.9rem;
    @media (max-width: 690px) {
      font-size: 0.7rem;
    }
  }

  .social-icons {
    display: flex;
    align-items: center;
    gap: 1rem;
    span {
      width: 2.3rem;
      height: 2rem;
      clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
      background-color: #01be96;
      position: relative;
      transition: transform 400ms ease-in-out;
      :hover {
        transform: rotate(360deg);
      }
    }

    a {
      color: #fff;
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
const Profile = styled.div`
  img {
    width: 25rem;
    filter: drop-shadow(0px 10px 10px #01be9570);
    transition: transform 400ms ease-in-out;
    @media (max-width: 790px) {
      width: 20rem;
    }

    @media (max-width: 660px) {
      width: 18rem;
    }

    @media (max-width: 640px) {
      width: 100%;
    }
  }

  :hover img {
    transform: translateY(-10px);
  }
`;
